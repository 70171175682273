import { forwardRef } from 'react';
import './TitlePage.css';

export const TitlePage = forwardRef((props, ref) => {
  return (
    <div ref={ref} className="section-title">
      <h2>{props.label}</h2>
      {props.children}
    </div>
  )
});
