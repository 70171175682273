import { useEffect } from "react";
import { Carrosel } from "../../../../components/carrosel/Carrosel"

import './fullScreenCarrocel.scss';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export const FullScreenCarrocel = ({ isFullScreen, handleClickImage, jsonImages, section, routeProject, keyRecord }) => {

  const history = useHistory();

  useEffect(() => {
    if (isFullScreen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isFullScreen]);


  function buttonCloseFullScreen() {
    return (

      <div className='container-icon-close-full-screen'>
        <div onClick={handleClickImage} className="icon-back-project">
          <span className="icon-close" >Fechar</span>
        </div>
      </div>
    )
  }

  return (
    <>
      {buttonCloseFullScreen()}
      <Carrosel
        section={section}
        table={routeProject}
        keyRecord={keyRecord}
        className="carroselArea"
        jsonImages={jsonImages}
        isFullScreen={isFullScreen}
        setIsFullScreen={handleClickImage}
      />
    </>
  )
}
