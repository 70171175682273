import { useEffect, useRef, useState } from 'react';

import { TextField } from '../../../../../components/textfield/TextField';
import { Button } from "../../../../../components/button/Button";
import { useAuth } from '../../../hooks/user/useAuth';

import _ from 'lodash';
import './BarDev.scss';
import { TextFieldArea } from './../../../../../components/textfieldarea/TextFieldArea';
import { FirebaseFileInput } from './components/image/FirebaseFileInput';
import { Values } from '../../../../../services/Values/Values';
export default function BarDev(props) {

  const { user, values, setValues } = useAuth();
  const [data, setData] = useState({});
  const [canSubmit, setCanSubmit] = useState(false);


  const detail = useRef(null);

  useEffect(() => {
    if (values?.data
      && !detail.originValues) {
      detail.originValues = _.clone(values?.data);
    }

    setData(values?.data || {});

  }, [values?.data]);

  useEffect(() => {
    setCanSubmit(!_.isEmpty(data));
  }, [data]);



  function handleChange(event, field) {
    if (values.record) {
      const record = (values.data && values.data.records) || [];
      const recFound = record.find(record => !record.key || record.key === values.id);

      if (recFound) {
        recFound[field] = event.target.value;
      } else {
        record.push({ [field]: event.target.value });
      }
      setValueOnData(null, 'records', record);
    } else {
      setValueOnData(event, field);
    }

  }

  function setValueOnData(event, field, record) {

    values.setData(prev => {
      return { ...prev, [field]: event ? event.target.value : record, changed: field };
    });

    setData(prev => {
      return { ...prev, [field]: event ? event.target.value : record, changed: field };
    });

  }

  function onSave() {
    const service = new Values(values.table);
    service.update(data);
    setValues(null);
  }

  function onCancel() {
    values.setData(detail.originValues);
    setValues(null);
  }

  let location = document.querySelector("#".concat(values?.section))?.offsetTop;
  location = location < 300 ? 0 : location - 300;

  return (

    <>
      {user && !_.isEmpty(values) &&
        <div ref={detail} style={{ bottom: `-${location}px` }} className='bar-devevelopment'>
          <div className='form-bar-dev'>
            {values.fields.map(component => {
              return <>
                {
                  component.type === 'text' &&
                  <TextField
                    id="name"
                    name="name"
                    title="Novo valor"
                    placeholder="novo valor"
                    onChange={(event) => handleChange(event, component.field)}
                    value={values?.data?.value}
                  />
                }
                {
                  component.type === 'textarea' &&
                  <TextFieldArea
                    id="name"
                    name="name"
                    title="Novo valor"
                    placeholder="novo valor"
                    onChange={(event) => handleChange(event, component.field)}
                    value={values?.data?.value}
                  />
                }
                {
                  component.type === 'file' &&
                  <FirebaseFileInput
                    id="name"
                    name="name"
                    title="Novo valor"
                    placeholder="novo valor"
                    action={(event) => handleChange(event, component.field)}
                    value={values?.data?.value}
                  />
                }
              </>
            })}
          </div>

          <footer>
            <Button onClick={onCancel} className="button-submit" text='Cancelar' />
            <Button onClick={onSave} disabled={!canSubmit} className="button-submit" text='Salvar' />
          </footer>
        </div>
      }
    </>
  );
}
