import { useEffect, useRef, useState } from 'react';
import SVG from 'react-inlinesvg';

import PrevIcon from './../../assets/icons/arrow-prev.svg'
import NextIcon from './../../assets/icons/arrow-next.svg'
import DeleteIcon from './../../assets/icons/delete-icon.svg'

import './Carrosel.scss';
import { useAuth } from '../../pages/login/hooks/user/useAuth';
import { Values } from '../../services/Values/Values';
export function Carrosel(props) {

  const sliderRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(props?.imageBackGround ? 1 : 0);
  const { user } = useAuth();

  useEffect(() => {
    let intervalo = null;
    if (!user) {
      intervalo = setInterval(() => {
        const index = currentIndex + 1;

        if (!props?.jsonImages[index]) {
          setCurrentIndex(0);
        } else {
          setCurrentIndex(index);
        }

        const prevImg = index - 1 >= 0 ? index - 1 : index;
        props?.setImageBackGround(prevImg);

      }, 5000);
    }
    return () => {
      clearInterval(intervalo);
    };
  }, [currentIndex]);


  function handlePrevClick(index) {
    const currentIndex = Number(index);
    const newIndex = currentIndex - 1;
    if (newIndex > -1) {
      const offsetWidth = getImgActive()[1].offsetWidth * newIndex;
      setCurrentIndex(newIndex);
      return { offsetWidth, i: newIndex };
    }
  }

  function handleClickImage() {
    props.handleClickImage && props.handleClickImage();
  }

  function handleNextClick(index) {
    const currentIndex = Number(index);
    const newIndex = currentIndex + 1;

    if (newIndex !== props.jsonImages.length) {
      setCurrentIndex(newIndex);
      const offsetWidth = getImgActive()[1].offsetWidth * newIndex;
      return { offsetWidth, i: newIndex };
    }

  }

  function getImgActive() {
    return getSlides().filter(([index, element]) => {
      return element.classList.contains('active')
    })[0];
  }

  function getSlides() {
    return Object.entries(sliderRef.current.children).filter(([index, element]) => {
      return element.classList.contains('gallery-item')
    });
  }

  function handleChangeImage(next) {

    var [index] = getImgActive();

    const handle = next ? handleNextClick : handlePrevClick

    const { offsetWidth, i } = handle(index);

    sliderRef.current.scrollTo(offsetWidth, 0);

    if (!offsetWidth) {
      setCurrentIndex(0);
    }
  }

  function deleteSelected(image) {
    const service = new Values(props.table, props.keyRecord);
    service.deleteRecord(image);
    if (props.jsonImages
      && props.jsonImages[currentIndex]
      && props?.imageBackGround) {
      props.setImageBackGround(currentIndex);
    }
  }

  function createItemCarrosel(image, index) {
    return (
      <div key={index} className={`gallery-item ${currentIndex === index ? "active" : ""}`}>
        {props?.imageBackGround && <div className='galley-item-border'></div>}
        <img className='gallery-item-image' onClick={() => handleClickImage()} src={image.url} alt='teste' />
        {user && <img className='delete-icon' onClick={() => deleteSelected(image)} src={DeleteIcon} alt='deletar item selecionado' />}
      </div>
    );
  }

  function getLastActive() {
    return props?.jsonImages?.length - 1 === currentIndex;
  }

  function getFirsActive() {
    return 0 === currentIndex;
  }

  function CarroselComp() {
    return (
      <div className='my-carrosel'>
        <div ref={sliderRef} className="my-gallery">
          {(
            props.jsonImages?.map((image, index) => createItemCarrosel(image, index))
          )}
          <SVG className={`button-prev ${getFirsActive() ? 'disabled' : ''}`} src={PrevIcon} alt="next image" onClick={() => !getFirsActive() && handleChangeImage(false)} />
          <SVG className={`button-next ${getLastActive() ? 'disabled' : ''}`} src={NextIcon} alt="previous image" onClick={() => !getLastActive() && handleChangeImage(true)} />

        </div>
      </div>
    );
  }

  if (props.isFullScreen) {
    return (
      <div className="full-scree-mode">
        <CarroselComp />
      </div>
    );
  }

  return (
    <CarroselComp />
  );

}