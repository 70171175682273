import BarDev from './pages/login/structure/components/bar/BarDev';

import { BrowserRouter, Route, Switch } from 'react-router-dom/cjs/react-router-dom';
import { Navigation } from './components/navigation/Navigation';
import { Contact } from './pages/contact/Contact';
import { About } from './pages/about/About';
import { Home } from './pages/home/Home';
import { Login } from './pages/login/Login';
import { AuthContextProvider } from './pages/login/context/AuthContextProvider';
import { PortFolioComp } from './pages/portifolios/portfolio/components/PortFolioComp';
import { PortfolioComercial } from './pages/portifolios/portfolio-comercial/PortfolioComercial';
import { PortfolioCorporativo } from './pages/portifolios/portfolio-corporativo/PortfolioCorporativo';
import { PortfolioUrbanismo } from './pages/portifolios/portfolio-urbanismo/PortfolioUrbanismo';

import './App.css';
function App() {


  function Site() {
    return (
      <>
        <Navigation />
        <Home />
        <PortFolioComp />
        <PortfolioComercial />
        <PortfolioCorporativo />
        <About />
        <Contact />
        <BarDev />
      </>
    )
  }

  return (
    <BrowserRouter>
      <AuthContextProvider>
        <Switch>
          <Route component={Site} path="/" exact />
          <Route component={Login} path="/admin" exact />
        </Switch>
      </AuthContextProvider>
    </BrowserRouter>
  );
}

export default App;
