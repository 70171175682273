
import './TextField.scss';

export function TextField(props) {
  return (
    <div className="form__group field">
      <input ref={props.reff} type="input" className="form__field"  {...props} />
      <label htmlFor={props.name} className="form__label">{props.title}</label>
    </div>
  );
}