import React, { useEffect, useRef, useState } from 'react';

import { TitlePage } from "../../../components/title/TitlePage";
import { Carrosel } from "../../../../components/carrosel/Carrosel";
import { Values } from '../../../../services/Values/Values';
import { Loading } from './../../../../components/loaders/loading/Loading';
import { EditMode } from './../../../login/structure/components/bar/components/editmode/EditMode';
import { isEmpty } from "lodash";

import './Project.scss';
import { useAuth } from '../../../login/hooks/user/useAuth';
import { FullScreenCarrocel } from '../components/FullScreenCarrocel';
export function Project({ project, setSelectProject, route, section }) {

  const [data, setData] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  const refCarrocel = useRef(null);
  const refTipo = useRef(null);
  const refLocal = useRef(null);
  const refDescription = useRef(null);
  const refProject = useRef(null);
  const routeProject = section ? `projects-${section}` : 'projects';
  const sectionProject = section || 'portfolio';

  useEffect(() => {

    const projectsValues = new Values(routeProject);
    const key = project.key;
    projectsValues.watch({ setData, key, callBack: () => setLoading(false) });

    return () => {
      projectsValues.destroy();
      setData(null);
    }
  }, []);

  function Project() {
    return (
      <div className='portfolio-projetct'>
        <div onClick={handleCloseProject} className="icon-back-project"><span className="icon-back " >Voltar</span> </div>
        <div ref={refCarrocel} className=" col-xs-12 col-md-12">
          <EditMode
            section={sectionProject}
            table={routeProject}
            parent={refCarrocel}
            data={data}
            setData={setData}
            record={true}
            fields={[{ field: 'url', type: 'file' }]}
          />
          {
            data?.records
            && <Carrosel
              section={sectionProject}
              table={routeProject}
              keyRecord={project.key}
              className="carroselArea"
              jsonImages={data?.records}
              isFullScreen={isFullScreen}
              handleClickImage={() => setIsFullScreen(true)}
            />

          }
        </div>
        <div className="content-info col-xs-12 col-md-12">

          <div ref={refDescription} className="project-text">
            <TitlePage label={project.name} />
            {data?.description?.split('\n').map((line, i) => (
              <React.Fragment key={i}>
                <label>
                  {line}
                </label>
                <br />
              </React.Fragment>
            ))}
          </div>

          <EditMode
            section={sectionProject}
            table={routeProject}
            parent={refDescription}
            data={data}
            setData={setData}
            fields={[{ field: 'description', type: 'textarea' }]}
          />
          <div className='informations-project'>
            <span>Tipo</span>
            <span ref={refTipo} className="font-1">{data?.type}
              <EditMode
                section={sectionProject}
                table={routeProject}
                parent={refTipo}
                data={data}
                setData={setData}
                fields={[{ field: 'type', type: 'text' }]}
              />
            </span>

            <span>Local</span>
            <span ref={refLocal} className="font-1">{data?.place}
              <EditMode
                section={sectionProject}
                table={routeProject}
                parent={refLocal}
                data={data}
                setData={setData}
                fields={[{ field: 'place', type: 'text' }]}
              />
            </span>

          </div>
        </div>
      </div>
    )
  }

  function handleCloseProject() {
    setSelectProject(null);
  }

  //{getProjectSelected && }
  /**
   *   function openProjetct(project) {
    setLoading(true);
    setTimeout(() => {
      setProjectSelected(project);
      setLoading(false);
    }, 2000);
  }
   */

  function PortfolioComponent() {
    return <div className="container min-100vh">
      <div ref={refProject} className="row" style={{ "justifyContent": "center" }}>
        <Project />
      </div>
    </div>;
  }


  if (isFullScreen) {
    return (
      <FullScreenCarrocel
        section={sectionProject}
        table={routeProject}
        keyRecord={project.key}
        className="carroselArea"
        jsonImages={data.records}
        isFullScreen={isFullScreen}
        handleClickImage={() => setIsFullScreen(false)}
      />
    )
  }

  return (

    <>
      {!loading && !user && (data == null || isEmpty(data)) ? <></>
        :
        <section id={section} className="project text-center cotainer-page">
          {(!loading && !isEmpty(data)) || user ? <PortfolioComponent /> : <Loading />}
        </section>
      }
    </>
  );

}