import { useEffect } from 'react';
import { useAuth } from '../../../../../hooks/user/useAuth';
import Edit from './../../../../../../../assets/icons/edit.svg';
import Add from './../../../../../../../assets/icons/add.svg';

import './EditMode.scss';
export function EditMode(props) {

  const { setValues, user } = useAuth();

  function changeMode() {
    setValues(props);
  }

  useEffect(() => {
    if (props.parent?.current) {
      props.parent.current.style.position = 'relative'
    };
  }, [props.parent]);

  function onMouseOverOrLeave(style) {
    if (props.parent?.current) {
      props.parent.current.style.border = style;
    };
  }

  return (
    user && <img
      onMouseOver={() => onMouseOverOrLeave('1px solid black')}
      onMouseLeave={() => onMouseOverOrLeave('')}
      className='edit-mode'
      onClick={changeMode}
      src={props.iconAdd ? Add : Edit}
      alt='set edit mode' />
  );
}