

import './Icon.css';
export default function Icon({ icon, label, url, alt, fontsize }) {

  return (
    <div className={`container-icon font-size-${fontsize}`}>
      <a className='icon' href={url} target="blank">
        <img src={icon} className="icons-social-media" alt={alt} />
      </a>
      <span>{label}</span>
    </div>
  );
}