
import Scroll from "./components/events/scroll/Scroll";
import { MenuIcon } from "./components/icon/menu/MenuIcon";
import SVG from 'react-inlinesvg';
import newLog from './../../assets/logo/1.png';

import routes from './data/routes.json';



import './Navigation.css';
import { useRef } from 'react';

export const Navigation = (props) => {

  const scroll = Scroll();

  const refMenu = useRef(null);

  function goTo(event) {
    if (!refMenu.current.classList.contains('collapsed')) {
      refMenu.current.click();
    }
    scroll.scrollToSection(event);
  }

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">

        <div className="navbar-header">

          <a className="page-scroll logo-link" href="#page-top">
            {/**<SVG className="logo" src={logo} alt="Studio AS" />*/}
            <img src={newLog} className="img-logo-header" alt="Studio AS" />
          </a>
          <MenuIcon reference={refMenu} />
        </div>

        <div className="container-routes collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right">
            {
              routes.map(route => (
                <li key={route.url}>
                  <a href={route.url} onClick={goTo} className="page-scroll">
                    {route.description}
                  </a>
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    </nav>
  );
};
