import { useState } from "react";
import { Portfolio } from './../Portfolio';
import { Project } from "../Project/Project";

export function PortFolioComp({ route , section}) {

  const [selectProject, setSelectProject] = useState(null);

  return (
    <>
      {!selectProject
        ? <Portfolio route={route} section={section}
          setSelectProject={setSelectProject} />
        : <Project route={route} section={section}
          setSelectProject={setSelectProject}
          project={selectProject} />}
    </>
  );
}