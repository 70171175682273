export const Image = ({onClick, title, image }) => {

  return (
    <div onClick={onClick} className="portfolio-item">
      <div className="hover-bg">
        {" "}
        <a title={title} className="container-image-gallery" data-lightbox-gallery="gallery1">
          <div className="hover-text">
            <h4>{title}</h4>
          </div>
          <img src={image} className="img-responsive" alt={title} />{" "}
        </a>{" "}
      </div>
    </div>
  );
};
