
import React, { useEffect, useRef, useState } from 'react';
import { Loading } from './../../components/loaders/loading/Loading';
import { Carrosel } from '../../components/carrosel/Carrosel';
import { EditMode } from '../login/structure/components/bar/components/editmode/EditMode';
import { Values } from '../../services/Values/Values';

import './Home.scss';
import Carousel from '../../components/Carousel/Carousel';
export const Home = (props) => {

  const [data, setData] = useState(null);
  const [imageBackGround, setImageBackGround] = useState(null);
  const [homeLoaded, setHomeLoaded] = useState(null);

  const refImgBackGroud = useRef(null);
  const refCarrocel = useRef(null);

  useEffect(() => {
    const homeValues = new Values('home');
    homeValues.watch({ setData });

    setTimeout(() => {
      setHomeLoaded(true);
    }, 2000);

    return () => {
      homeValues.destroy();
      setData(null);
    }
  }, []);

  function loadImageBackground() {
    if (!imageBackGround && data && data?.records && data?.records[0]) {
      setImageBackGround(data.records[0]['url']);
    }
  }

  function changeImg(index) {
    if (data?.records[index]) {
      setImageBackGround(data?.records[index]['url']);
    } else if (data?.records[index - 1]) {
      setImageBackGround(data?.records[0]['url']);
    }
  }

  loadImageBackground();

  function HomeComponent() {
    return (
      <>
        {imageBackGround && <img className='img-back-ground' ref={refImgBackGroud} src={imageBackGround} alt='carrocel' />}
        <div className='desc-home home-item-desc'>
        </div>
        <div ref={refCarrocel} className='container-corrosel'>
          <div className='flex-1'></div>
          {
            (data && data?.records && data?.records.length > 1 ?
              <Carrosel
                className="carroselArea"
                jsonImages={data?.records}
                keyRecord={data.key}
                section={'home'}
                table='home'
                imageBackGround={refImgBackGroud}
                setImageBackGround={changeImg} >
              </Carrosel>
              : false)
          }
          <EditMode
            section={'home'}
            table={'home'}
            parent={refCarrocel}
            data={data}
            setData={setData}
            record={true}
            fields={[
              { field: 'url', type: 'file' }
            ]}
          />
          <div className='flex-1'></div>
        </div>
      </>
    )
  }

  function LoadCarrosel() {
    if (window.innerWidth <= 1100) {
      return (
        <Carousel images={data?.records} />
      )
    }
    return HomeComponent();
  }

  return (
    <section id="home" className="cotainer-page cotainer-page">
      {homeLoaded ? <LoadCarrosel /> : <Loading />}
    </section>
  );
};
