import Instagram from './instagram/Instagram';
import Whatsapp from './whatsapp/Whatsapp';
import Facebook from './facebook/Facebook';
import Email from './email/Email';

import './SocialBar.css';

export default function SocialBar({ justSocialMedia = false }) {
  return (
    <div className='icons-social-media'>
      <Instagram />
      {!justSocialMedia && <Whatsapp />}
      <Facebook />
      {!justSocialMedia && <Email />}
    </div>
  );

}