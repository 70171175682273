import './Loading.css';

export function Loading() {
  
  return (
    <div id='content-loader'>
      <div id="load">
        <div>O</div>
        <div>D</div>
        <div>N</div>
        <div>A</div>
        <div>G</div>
        <div>E</div>
        <div>R</div>
        <div>R</div>
        <div>A</div>
        <div>C</div>
      </div>
    </div>
  )
}