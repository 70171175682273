import { useState } from "react";
import { Button } from "../../components/button/Button";
import { TextField } from "../../components/textfield/TextField";
import { TitlePage } from "../components/title/TitlePage";
import { useAuth } from "./hooks/user/useAuth.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

import './Login.scss';

export function Login() {

  const { user, signInWithEmailAndPassword } = useAuth();
  const history = useHistory();
  
  const [mail, setMail] = useState('');
  const [password, setPassword] = useState('');


  function handleLogin(event) {
    event.preventDefault();
    verifyUser();
  }


  async function verifyUser() {
    if (!user) {
      await signInWithEmailAndPassword(mail, password);
    }
    history.push('/');
  }

  function reload() {
    history.push('/');
    window.location.reload();
  }

  function getForm() {
    return (
      <div className="btn-to-sent-code-sms">
        <TextField className="form__field" placeholder="email" onChange={(event) => setMail(event.target.value)}></TextField>
        <TextField className="form__field" placeholder="senha" type='password' onChange={(event) => setPassword(event.target.value)}></TextField>
        <Button className="button-submit" onClick={handleLogin} text={'Login'} type="submit"></Button>
      </div>
    )
  }

  return (
    <>
      {!user ?

        < section id="login" className="cotainer-page" >
          <div className="container ">
            <div className="col-md-12">
              <div className="row">
                <TitlePage label="Login" />
                {getForm()}
              </div>
            </div>
          </div>
        </section >
        :
        reload()
      }
    </>
  );
}