
export default function Scroll() {

  function getDistanceFromTheTop(id) {
    if(id === '#home') return 0;
    return document.querySelector(id).offsetTop;
  }

  function scrollToSection(event) {
    event.preventDefault && event.preventDefault();
    const href = event.target.getAttribute("href");
    const distanceFromTheTop = getDistanceFromTheTop(href);
    smoothScrollTo(0, distanceFromTheTop);
  }

  function addjustScroll(href) {
    const distanceFromTheTop = getDistanceFromTheTop(href);
    smoothScrollTo(0, distanceFromTheTop);
  }

  function returnToPortfolio() {
    const distanceFromTheTop = document.getElementById('portfolio').offsetTop;
    
    window.scroll(0, distanceFromTheTop);
  }

  function smoothScrollTo(endX, endY, duration) {
    const startX = window.scrollX || window.pageXOffset;
    const startY = window.scrollY || window.pageYOffset;
    const distanceX = endX - startX;
    const distanceY = endY - startY;
    const startTime = new Date().getTime();

    duration = typeof duration !== "undefined" ? duration : 1000;

    const easeInOutQuart = (time, from, distance, duration) => {
      if ((time /= duration / 2) < 1)
        return (distance / 2) * time * time * time * time + from;
      return (-distance / 2) * ((time -= 2) * time * time * time - 2) + from;
    };

    const timer = setInterval(() => {
      const time = new Date().getTime() - startTime;
      const newX = easeInOutQuart(time, startX, distanceX, duration);
      const newY = easeInOutQuart(time, startY, distanceY, duration);
      if (time >= duration) {
        clearInterval(timer);
      }
      window.scroll(newX, newY);
    }, 1000 / 60);
  }

  return { scrollToSection, addjustScroll, returnToPortfolio }
}