import firebase from "firebase";

import 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyCaCTGjd_AOgiUob3gy46YNE7ydkt4VX78",
  authDomain: "studioamandasa-9a123.firebaseapp.com",
  projectId: "studioamandasa-9a123",
  storageBucket: "studioamandasa-9a123.appspot.com",
  messagingSenderId: "148030671727",
  appId: "1:148030671727:web:ce4ba8040d5a14f7c2369a"
};

firebase.initializeApp(firebaseConfig);

const database = firebase.database();
const storage = firebase.storage();
const auth = firebase.auth();

export { database, auth, firebase, storage };
