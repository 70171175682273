import React, { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';


import './carroucel.css';

const Carousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {

    let intervalo = null;
    intervalo = setInterval(() => {
      setCurrentIndex((prevIndex) => (images[prevIndex + 1] ? prevIndex + 1 : 0));
    }, 5000);
    return () => {
      clearInterval(intervalo);
    };
  }, [currentIndex]);

  const handleSwipedLeft = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handleSwipedRight = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleSwipedLeft,
    onSwipedRight: handleSwipedRight,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  return (
    <div {...handlers} id='carroucel'>
      {images?.map((image, index) => (
        <img
          key={index}
          src={image.url}
          alt=""
          className={`carousel-image ${currentIndex === index ? 'active' : ''}`}
        />
      ))}
    </div>
  );
};

export default Carousel;
