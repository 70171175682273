import { database } from "../firebase";
import { isEmpty } from 'lodash';

export class Values {

  constructor(url, id) {
    this.url = url;
    if (id) {
      this.ref = database.ref(url).child(id);
    } else {
      this.ref = database.ref(url);
    }
  }

  deleteRecord(imagem) {
    this.ref.child(`/records/${imagem.key}`).remove();
  }

  async insertOrUpdateProjects(data) {

    const snapshot = await this.ref.child(data.key).once('value')

    if (snapshot.exists()) {
      await this.ref.update({ [data.key]: data });
    } else {
      await this.ref.set({ [data.key]: data });
    }

  }

  async insertOrUpdateProject(data) {
    if (data?.records?.some(rec => !rec.key)) {
      const records = data.records.filter(rec => !rec.key);

      for await (let record of records) {
        await this.ref.child(data.key).child('records').push(record);
      }
    } else if (data?.key) {
      if (data?.records?.length) {
        const newArray = {};
        data.records =  data.records.reverse();
        
        data.records.forEach(item => {
          newArray[item.key] = item;
        });
        data.records = newArray;
      }
      const fieldToUpdate = data['changed'];

      await this.ref.child(data.key).update({ [fieldToUpdate]: data[fieldToUpdate] });
    } else {
      this.insertOrUpdateProjects(data);
    }
  }

  async update(data) {

    if (['projects-portfolio-comercial', 'projects-portfolio-corporativo', 'projects'].includes(this.url)) {
      return this.insertOrUpdateProject(data);
    }

    let response = null;
    try {
      if (data?.records?.some(rec => !rec.key)) {
        const records = data.records.filter(rec => !rec.key);

        for (let record of records) {
          this.ref.child(data.key).child('records').push(record);
        }
      } else if (data?.key) {
        if (data?.records?.length) {
          const newArray = {};
          data.records =  data.records.reverse();

          data.records.forEach(item => {
            newArray[item.key] = item;
          });
          data.records = newArray;
        }
        const fieldToUpdate = data['changed'];
        response = await this.ref.child(data.key).update({ [fieldToUpdate]: data[fieldToUpdate] });
      } else {
        response = await this.ref.update(data);
      }
    } catch (error) {
      throw error;
    }

    return response
  }

  async gambi(data) {
    let response = null;
    try {
      response = await this.ref.push(data);
    } catch (error) {
      throw error;
    }

    return response
  }

  extractValues(response, key) {
    let data = response.val() || [];

    key = key || response._delegate._node.children_.root_.key;
    if (data[key]) {
      data = data[key];
      data.key = key;

      if (data.records) {
        data.records = Object.entries(data.records).map(([key, record]) => { return { key, ...record } }).reverse();
      }
    }
    if (key) {
      data.key = key;
    }
    return data;
  }

  
  watch({callBack, setData, key}) {
    this.ref.on('value', (values) => {
      setData(this.extractValues(values, key));
      callBack && callBack();
    });
  }

  destroy() {
    this.ref.off();
  }
}