
import whatsapp from './../../assets/social-media/whats-app.png';
import instagram from './../../assets/social-media/instagram.png';
import facebook from './../../assets/social-media/facebook.png';
import email from './../../assets/social-media/email.png';
import Icon from '../icon/Icon';
import SocialBar from '../socialbar/SocialBar';

import './Footer.css';
import Whatsapp from '../socialbar/whatsapp/Whatsapp';

export function Footer() {
  return (
    <div className='container-footer'>
      <div className='whatsapp-fixed'>
        <Whatsapp />
      </div>
      <div className="footer-bar" >
        <div className='contato'>
          <h3>Fale Conosco</h3>
          <Icon fontsize={12} icon={whatsapp} label={'+55(47)99622-2542'} url={'https://api.whatsapp.com/send?phone=5547996222542'} alt='whatsapp' />
          <Icon fontsize={12} icon={email} label={'amanda.arq.sa@gmail.com'} url={'mailto:studioas.arq96@gmail.com?subject=Contato%20via%20link'} alt='whatsapp' />
        </div>
        <div className='redes-sociais'>
          <h3>Studio AS</h3>
          <span>Nos acompanhe</span>
          <SocialBar justSocialMedia={true} />
        </div>
      </div>
      <div className="footer">
        <div className="container text-center">
          <p>
            &copy; 2023 Development by Diogo Galdino Stihler{" "}
          </p>
        </div>
      </div>
    </div>
  );
}