import './Contact.css';

import { useState } from "react";

import { TitlePage } from "../components/title/TitlePage";
import { TextFieldArea } from "../../components/textfieldarea/TextFieldArea";
import { TextField } from "../../components/textfield/TextField";
import { Button } from "../../components/button/Button";
import { Footer } from "../../components/footer/Footer";

import Icon from "../../components/icon/Icon";
import whatsapp from './../../assets/social-media/whats-app.png';
import emailjs from '@emailjs/browser';

const initialState = {
  name: "",
  email: "",
  message: "",
};

export function Contact(props) {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    emailjs
      .sendForm("service_oldikpa", "template_f8qldu5", e.target, "6sgn2ZdewwgTpC7kY")
      .then(
        (result) => {
          console.log(result.text);
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <section id="contact" className="cotainer-page" >
      <div className="container ">
        <div className="col-md-12">
          <div className="row">
            <TitlePage label="Contato" />

            <form name="sentMessage" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <TextField
                      id="name"
                      name="user_name"
                      title="Nome"
                      required
                      placeholder="nome"
                      onChange={handleChange}
                    />

                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">

                    <TextField
                      id="email"
                      name="user_email"
                      title="Email"
                      required
                      placeholder="email"
                      onChange={handleChange}
                    />

                  </div>
                </div>
              </div>
              <div className="form-group">
                <TextFieldArea
                  id="message"
                  name="message"
                  title="Mensagem"
                  placeholder="Mensagem"
                  required
                  onChange={handleChange}
                />
              </div>
              <div id="success"></div>
              <Button className="button-submit" text='Enviar' />
            </form>

          </div>
        </div>
        
      </div>
      <Footer />
    </section>
  );
};
