import { useEffect, useRef, useState } from "react";
import { isEmpty } from "lodash";

import { TitlePage } from "../../components/title/TitlePage";
import { Image } from "../../../components/image/Image";
import { Loading } from "../../../components/loaders/loading/Loading";
import { EditMode } from "../../login/structure/components/bar/components/editmode/EditMode";
import { Values } from "../../../services/Values/Values";
import { useAuth } from './../../login/hooks/user/useAuth';

import DeleteIcon from './../../../assets/icons/delete-icon.svg';

import './Portfolio.css';
import Scroll from "../../../components/navigation/components/events/scroll/Scroll";
export const Portfolio = (props) => {

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const scroll = Scroll();

  const refTitle = useRef(null);
  const refProjects = useRef(null);
  const refProject = useRef([]);
  const { user } = useAuth();
  const routeProject = props.route || 'portfolio';

  useEffect(() => {

    const portfolioValues = new Values(routeProject);
    portfolioValues.watch({ setData, callBack: () => setLoading(false) });
    return () => {
      portfolioValues.destroy();
      setData(null);
    }
  }, []);

  function deleteSelected(image) {
    const service = new Values(routeProject, data.key);
    service.deleteRecord(image);
  }

  function handleSelectProject(project) {
    scroll.addjustScroll(`#${routeProject}`);
    props?.setSelectProject(project);
  }

  function Gallery() {
    return (
      data?.records?.map((project, i) => (
        <div key={project?.name} className="col-xs-12 col-md-4">
          <Image
            title={project?.name}
            onClick={() => handleSelectProject(project)}
            image={project?.coverUrl}
          />
          {
            <>
              {user && <img className='delete-icon' onClick={() => deleteSelected(project)} src={DeleteIcon} alt='deletar item selecionado' />}
              <EditMode
                section={routeProject}
                table={routeProject}
                parent={refProject.current[i]}
                data={data}
                setData={setData}
                record={true}
                id={project.key}
                fields={[{ field: 'coverUrl', type: 'file' }, { field: 'name', type: 'text' }]}
              />
            </>
          }
        </div>
      ))
    );
  }

  function PortfolioComponent() {
    return <div className="container min-100vh">
      <div ref={refProjects} className="row">

        <TitlePage ref={refTitle} label={data?.title}>
          <EditMode
            section={routeProject}
            table={routeProject}
            parent={refTitle}
            data={data}
            setData={setData}
            fields={[{ field: 'title', type: 'text' }]}
          />
        </TitlePage>
        <div className="portfolio-items">
          <Gallery />
        </div>
      </div>
      <EditMode
        section={routeProject}
        iconAdd={true}
        table={routeProject}
        parent={refProjects}
        data={data}
        setData={setData}
        record={true}
        fields={[{ field: 'coverUrl', type: 'file' }, { field: 'name', type: 'text' }]}
      />
    </div>;
  }

  return (
    <>
      {!loading && !user && (data == null || isEmpty(data)) ? <></>
        :
        <section id={routeProject} className="text-center cotainer-page">
          {(!loading && !isEmpty(data)) || user ? <PortfolioComponent /> : <Loading />}
        </section>
      }
    </>
  );
};
