import whatsapp from './../../../assets/social-media/whats-app.png';

import './Whatsapp.css';

export default function Whatsapp() {
  return (
    <a className='icon-whatsapp' href={'https://api.whatsapp.com/send?phone=5547996222542'} target="blank">
      <img src={whatsapp}  alt={'whatsapp'} />
    </a>
  );
}