
import './MenuIcon.css';

export function MenuIcon(props) {

  return (
    <button
      ref={props.reference}
      type="button"
      className="navbar-toggle collapsed"
      data-toggle="collapse"
      data-target="#bs-example-navbar-collapse-1"
    >
      {" "}
      <span className="sr-only">Toggle navigation</span>{" "}
      <span className="icon-bar"></span>{" "}
      <span className="icon-bar"></span>{" "}
      <span className="icon-bar"></span>{" "}
    </button>
  );
}