import { useState } from 'react';
import { storage } from './../../../../../../../services/firebase';
import { Button } from '../../../../../../../components/button/Button';

import './FirebaseFileInput.scss';
import { useEffect } from 'react';
export function FirebaseFileInput(props) {

  const [file, setFile] = useState(null);
  const [url, setUrl] = useState('');

  function handleFileChange(e) {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  }

  function handleFileUpload() {
    const storageRef = storage.ref('about/perfil/');
    const fileRef = storageRef.child(file.name);

    fileRef.put(file).then(() => {
      console.log('File uploaded successfully');
      fileRef.getDownloadURL().then((url) => {
        props.action({ target: { value: url } })
      });
    });
  }


  function handleImageUpload(event) {
    const reader = new FileReader();

    reader.onload = function (event) {
      const img = new Image();
      img.onload = function () {
        props.action({ target: { value: img.src } })
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  }

  return (
    <div className='firebase-file-input'>
      <input id="button" className='form__field' type="file" onChange={handleFileChange} />
      {file && <Button onClick={handleImageUpload} text={'Upload arquivo selecionado'} />}
      {url && <img src={url} alt="Uploaded file" />}
    </div>
  );
}