import React, { useEffect, useState, useRef } from "react";
import { TitlePage } from "../components/title/TitlePage";
import { Loading } from './../../components/loaders/loading/Loading';
import { isEmpty } from "lodash";
import { EditMode } from "../login/structure/components/bar/components/editmode/EditMode";


import './About.scss';
import { Values } from './../../services/Values/Values';
import { useAuth } from "../login/hooks/user/useAuth";
export const About = (props) => {

  const [data, setData] = useState({});

  const { user } = useAuth();

  useEffect(() => {

    const aboutValues = new Values('about');
    aboutValues.watch({ setData });

    return () => {
      aboutValues.destroy();
      setData(null);
    }
  }, []);

  const refImage = useRef(null);
  const refName = useRef(null);
  const refAbout = useRef(null);

  function AboutComponent() {
    return <div className="container">

      <div className="row">

        <div className="portfolio-items about-container">
          <div id="img-about" className="">
            {" "}
            <div>
              <img ref={refImage} src={data?.urlImage} className="img-responsive" alt="" />
              <EditMode
                section={'about'}
                table={'about'}
                parent={refImage}
                data={data}
                setData={setData}
                fields={[{ field: 'urlImage', type: 'file' }]}
              />
            </div>

            <div className="container-name-about" ref={refName}>
              <h3>{data?.name}</h3>
              <EditMode
                section={'about'}
                table={'about'}
                parent={refName}
                data={data}
                setData={setData}
                fields={[{ field: 'name', type: 'text' }]}
              />
            </div>

            {" "}
          </div>
          <div ref={refAbout}>
            <div className="about-text">
              {data?.paragraph?.split('\n').map((line, i) => (
                <React.Fragment key={i}>
                  <label>
                    {line}
                  </label>
                  <br />
                </React.Fragment>
              ))}
              <EditMode
                section={'about'}
                table={'about'}
                parent={refAbout}
                data={data}
                setData={setData}
                fields={[{ field: 'paragraph', type: 'textarea' }]}
              />
            </div>
          </div>
        </div>
      </div>
    </div >
  }

  return (
    <section id="about" className="cotainer-page cotainer-page">
      {!isEmpty(data) || user ? <AboutComponent /> : <Loading />}
    </section>
  );
};
