import { createContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { auth, firebase } from "../../../services/firebase";

export const AuthContext = createContext({});

export function AuthContextProvider(props) {
  const [user, setUser] = useState(null);
  const [verificationId, setVerificationId] = useState(null);
  const [values, setValues] = useState(null);

  const history = useHistory();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        const { uid } = user

        setUser({
          id: uid,
          admin: !!user.phoneNumber
        })
      }
    });

    return () => {
      unsubscribe();
    }
  }, []);

  async function signInWithEmailAndPassword(email, password) {
    try {
      const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);

      const user = userCredential.user;

      if (user) {
        const { uid } = user;

        setUser({
          id: uid,
          admin: false
        });
      }
    } catch (error) {
      alert(error?.message);
    }
  }


  function setUserAfter(userData) {
    if (userData.user) {
      const { uid } = userData.user

      setUser({
        id: uid,
        admin: !!userData.user.phoneNumber
      });
    }
  }

  function signOut() {
    auth.signOut().then(() => {
      setUser(undefined);
      history.push('/');
    });
  }

  function hasUser() {
    return true;
  }

  return (
    <AuthContext.Provider value={{ values, setValues, user, signInWithEmailAndPassword, signOut, hasUser, setUserAfter }}>
      {props.children}
    </AuthContext.Provider>
  );
}